import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Icon } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import 'semantic-ui-css/semantic.min.css';
import '../css/PrivacyPolicy.css';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twitter: "https://twitter.com/666_stories",
      facebook: "",
      instagram: "https://instagram.com/666.stories"
    }
  }

  componentDidMount() {

  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='privacypolicytitle'>Privacy Policy</h1>

        <div align='center'>
          <Typography align='center' style={{ margin: '2em' }}>
            {"We store information (email address, name, and social media accounts) in our database if you include such information when submitting content."}
          </Typography>
          <Typography align='center' style={{ margin: '2em' }}>
            {"Any information you submit will be published on our website. We do not ask for these information though providing these will help you connect with our readers."}
          </Typography>
          <Typography align='center' style={{ margin: '2em' }}>
            {"666stories666@gmail.com"}
          </Typography>
          <a href={this.state.twitter} style={{ color: '#000' }}> <Icon name="twitter" size="big"/> </a>
          <a href={this.state.facebook} style={{ color: '#000' }}> <Icon name="facebook" size="big"/> </a>
          <a href={this.state.instagram} style={{ color: '#000' }}> <Icon name="instagram" size="big"/> </a>
        </div>

      </div>
    );
  }
}

export default PrivacyPolicy;
