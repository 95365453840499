import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Card, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import '../css/ReadTypeC.css';
import stories from '../../files/stories/json/stories-666-char.json';

class ReadTypeC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "c",
      msg: "Read this story at https://666stories.com/story/",
      stories: []
    }
  }

  componentDidMount() {
    //console.log("story id", this.props.match.params.id);
    this.setState({stories: [...stories]})
  }

  renderStory() {
    if(this.state.stories.length !== 0) {
      var filteredstory = this.state.stories.filter(story => story.id.toString() === this.props.match.params.id);

      return filteredstory.map((item, index) => {
        const { id, title, story, author, author_contact_details } = item;

        var message = story.substring(0,150) + " | " + this.state.msg + this.state.type + "/" + id.toString() + " | @666_stories";
        var link = "https://twitter.com/intent/tweet?text=" + encodeURI(message);

        return(
          <tr>
            <td>
              <Card>
                <Card.Content header={title} />
                <Card.Content description={story} textAlign='center' />
                <Card.Content extra>
                  <span style={{ float: 'left' }}>
                    <Icon name="user" />
                    <Link
                      to={{
                        pathname: '/viewauthor',
                        state: {
                          author: author,
                          twitter: author_contact_details[0],
                          facebook: author_contact_details[1],
                          instagram: author_contact_details[2],
                          email: author_contact_details[3],
                          website: author_contact_details[4],
                        }
                      }}
                    >
                      {author}
                    </Link>
                  </span>
                  <span style={{ float: 'right' }}>
                    <Popup content="Share" trigger={<a href={link}> <Icon name="share"/> </a>} />
                  </span>
                </Card.Content>
              </Card>
            </td>
          </tr>
        );
      });
    }
  }

  render() {
    //console.log("this.state.stories: ", this.state.stories)
    return(
      <div>
        <PersistentDrawerLeft />

        <div id="readtypecdiv" align="center" style={{marginTop: '3em'}}>
          <table id="readtypectable">
            <tbody>
              {this.renderStory()}
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

export default ReadTypeC;
