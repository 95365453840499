import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import emailjs from 'emailjs-com';
import { Button, Dropdown, Form, Icon, Input, TextArea } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import 'semantic-ui-css/semantic.min.css';
import '../css/Submit.css';

class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [{key: "a", text: "6-6-6", value: "a"}, {key: "b", text: "66 Words", value: "b"}, {key: "c", text: "666 Characters", value: "c"}],
      type: "a",
      title: "",
      story: "",
      author: "",
      twitter: "",
      facebook: "",
      instagram: "",
      email: "",
      website: "",
      isSubmitting: false,
      submitted: false,
      error: false,
      isStoryEmpty: false
    }

    this.onChangeSelection = this.onChangeSelection.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onStoryChange = this.onStoryChange.bind(this);
    this.onAuthorChange = this.onAuthorChange.bind(this);
    this.onTwitterChange = this.onTwitterChange.bind(this);
    this.onFacebookChange = this.onFacebookChange.bind(this);
    this.onInstagramChange = this.onInstagramChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onWebsiteChange = this.onWebsiteChange.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {

  }

  onChangeSelection(e, data) {
    this.setState({type: data.value});
  }

  onTitleChange(e, data) {
    this.setState({title: data.value});
  }

  onStoryChange(e, data) {
    this.setState({story: data.value});
  }

  onAuthorChange(e, data) {
    this.setState({author: data.value});
  }

  onTwitterChange(e, data) {
    this.setState({twitter: data.value});
  }

  onFacebookChange(e, data) {
    this.setState({facebook: data.value});
  }

  onInstagramChange(e, data) {
    this.setState({instagram: data.value});
  }

  onEmailChange(e, data) {
    this.setState({email: data.value});
  }

  onWebsiteChange(e, data) {
    this.setState({website: data.value});
  }

  onSubmit() {
    if(this.state.story.length === 0) {
      this.setState({isStoryEmpty: true});
    } else {
      this.setState({isSubmitting: true});
      var mail = {};
      mail.author = this.state.author === "" ? "Anonymous" : this.state.author;
      mail.story_type = this.state.type;
      mail.story_title = this.state.title;
      mail.story = this.state.story;
      mail.twitter = this.state.twitter;
      mail.facebook = this.state.facebook;
      mail.instagram = this.state.instagram;
      mail.email = this.state.email;
      mail.website = this.state.website;

      emailjs.send('gmail_666_stories', 'story_666_stories', mail, 'user_ANTAfHrBooXuOmxNxi1Yn')
        .then((result) => {
          if(result.status === 200) {
            this.setState({submitted: true, isSubmitting: false});
          }
          //console.log(result.text);
        }).catch(() => {
          this.setState({error: true})
        });
    }
  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='submittitle'>Submit</h1>

        {!this.state.submitted && !this.state.error ?
          <div id="submitdiv" align="center">
            <table id="submittable">
              <tbody>
                <tr>
                  <td>
                    <Typography>
                      Type:
                    </Typography>
                    </td>
                    <td>
                    <Dropdown
                      placeholder='6-6-6'
                      selection
                      options={this.state.types}
                      onChange={this.onChangeSelection}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Title:
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="Title"
                        value={this.state.title}
                        onChange={this.onTitleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Story:
                    </Typography>
                  </td>
                  <td>
                    <Form>
                      <TextArea
                        placeholder="Story"
                        value={this.state.story}
                        onChange={this.onStoryChange}
                      />
                    </Form>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                    </Typography>
                  </td>
                  <td>
                    {this.state.type === "a" || this.state.type === "b"  ?
                      <Typography>
                        {(this.state.story.length === 0 ? "0" : this.state.story.trim().replace(/\s+/gi, ' ').split(' ').length) + " word(s)"}
                      </Typography>
                    :
                      <Typography>
                        {this.state.story.length + " character(s)"}
                      </Typography>
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Author (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="Name"
                        value={this.state.author}
                        onChange={this.onAuthorChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Twitter (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="username"
                        value={this.state.twitter}
                        onChange={this.onTwitterChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Facebook (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="username"
                        value={this.state.facebook}
                        onChange={this.onFacebookChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Instagram (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="username"
                        value={this.state.instagram}
                        onChange={this.onInstagramChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Email Address (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="name@email.com"
                        value={this.state.email}
                        onChange={this.onEmailChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      Website (Optional):
                    </Typography>
                    </td>
                    <td>
                    <Input
                        placeholder="www.website.com"
                        value={this.state.website}
                        onChange={this.onWebsiteChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        : null}

        {this.state.submitted ?
          <div align="center">
            <Typography style={{ color: '#00f700' }}>
              Story Submitted!
            </Typography>
          </div>
        : null}

        {this.state.error ?
          <div align="center">
            <Typography style={{ color: '#f70000' }}>
              Error submission!
            </Typography>
            <Typography style={{ color: '#f70000' }}>
              Send your story to 666stories666@gmail.com.
            </Typography>
          </div>
        : null}

        {this.state.isStoryEmpty && !this.state.submitted && !this.state.error ?
          <div align="center">
            <Typography style={{ color: '#f70000' }}>
              Story field is empty!
            </Typography>
          </div>
        : null}

        {!this.state.submitted && !this.state.error ?
          <div id="submitbutton" align="center">
              <Button
                primary
                style={{ marginTop: '10px', color: "white", background: "#000" }}
                loading={this.state.isSubmitting}
                onClick={this.onSubmit}
              >
                Submit
              </Button>
          </div>
        : null}

      </div>
    );
  }
}

export default Submit;
