import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Icon } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import 'semantic-ui-css/semantic.min.css';
import '../css/Photos.css';

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  componentDidMount() {

  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='photostitle'>Photos</h1>

        <div align='center'>
          <Typography align='center' style={{ margin: '2em' }}>
            {"Soon."}
          </Typography>
        </div>

      </div>
    );
  }
}

export default Photos;
