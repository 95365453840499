import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Icon } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import 'semantic-ui-css/semantic.min.css';
import '../css/About.css';
import logo from '../../logo_v1.jpg';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twitter: "https://twitter.com/666_stories",
      facebook: "",
      instagram: "https://instagram.com/666.stories"
    }
  }

  componentDidMount() {

  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='abouttitle'>About</h1>

        <div align='center'>
          <img src={logo} alt="Logo" height="75em" width="75em"/>
          <Typography align='center' style={{ margin: '2em' }}>
            {"666stories is a collection of creepy, scary, terrifying, hair-raising, spine-chilling, blood-curdling, and unpleasant, weird, and disturbing stories both real and fiction submitted by creative writers and people who unfortunately have experienced such events or occurrences in their lives."}
          </Typography>
          <Typography align='center' style={{ margin: '2em' }}>
            {"Any story submitted is not guaranteed true or has happened in real life. Please contact the writer/author for details. Contact details may be provided with their story submission."}
          </Typography>
          <Typography align='center' style={{ margin: '2em' }}>
            {"Happy reading! Ha Ha Ha Ha Ha"}
          </Typography>
          <a href={this.state.twitter} style={{ color: '#000' }}> <Icon name="twitter" size="big"/> </a>
          <a href={this.state.facebook} style={{ color: '#000' }}> <Icon name="facebook" size="big"/> </a>
          <a href={this.state.instagram} style={{ color: '#000' }}> <Icon name="instagram" size="big"/> </a>
        </div>

      </div>
    );
  }
}

export default About;
