import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#000000'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    padding: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}>
          </div>
          <Link to='/about' style={{ textDecoration: 'none', color: 'white' }}>
            <Typography>ABOUT</Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>

        <Divider />

        <List>
          <ListItem button key="Search">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <Link to='/search' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="Search" />
            </Link>
          </ListItem>

          <ListItem button key="Stories">
            <ListItemIcon>
              <LibraryBooksOutlinedIcon />
            </ListItemIcon>
            <Link to='/stories' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="Stories" />
            </Link>
          </ListItem>

          <ListItem button key="Photos">
            <ListItemIcon>
              <ImageOutlinedIcon />
            </ListItemIcon>
            <Link to='/photos' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="Photos" />
            </Link>
          </ListItem>

          <ListItem button key="About">
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <Link to='/about' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="About" />
            </Link>
          </ListItem>

          <ListItem button key="FAQs">
            <ListItemIcon>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <Link to='/faqs' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="FAQs" />
            </Link>
          </ListItem>

          <ListItem button key="PrivacyPolicy">
            <ListItemIcon>
              <GavelOutlinedIcon />
            </ListItemIcon>
            <Link to='/privacypolicy' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="Privacy Policy" />
            </Link>
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem button key="Submit">
            <ListItemIcon>
              <CreateOutlinedIcon />
            </ListItemIcon>
            <Link to='/submit' style={{ textDecoration: 'none', color: '#111' }}>
              <ListItemText primary="Submit" />
            </Link>
          </ListItem>
        </List>

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
      <div className={classes.drawerHeader} />
      </main>
    </div>
  );
}

export default PersistentDrawerLeft;
