import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../css/ViewAuthor.css';

class ViewAuthor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  componentDidMount() {

  }

  render() {
    const { author, twitter, facebook, instagram, email, website } = this.props.location.state;
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='authortitle'>{author}</h1>

        <div align='center'>
          <table id='authordetailstable'>
            <tbody>
              { twitter !== "no" ?
                <tr>
                  <td>
                    <Icon name='twitter' />
                    <a href={"https://www.twitter.com/" + twitter}>
                      {twitter}
                    </a>
                  </td>
                </tr>
              : null }
              { facebook !== "no" ?
                <tr>
                  <td>
                    <Icon name='facebook' />
                    <a href={"https://www.facebook.com/" + facebook}>
                      {facebook}
                    </a>
                  </td>
                </tr>
              : null }
              { instagram !== "no" ?
                <tr>
                  <td>
                    <Icon name='instagram' />
                    <a href={"https://www.instagram.com/" + instagram}>
                      {instagram}
                    </a>
                  </td>
                </tr>
              : null }
              { email !== "no" ?
                <tr>
                  <td>
                    <Icon name='mail' />{email}
                  </td>
                </tr>
              : null }
              { website !== "no" ?
                <tr>
                  <td>
                    <Icon name='globe' />
                    <a href={"http://" + website}>
                      {website}
                    </a>
                  </td>
                </tr>
              : null }
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

export default ViewAuthor;
