import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Icon } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import 'semantic-ui-css/semantic.min.css';
import '../css/Faqs.css';

class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  componentDidMount() {

  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='faqstitle'>FAQs</h1>

        <div align='center'>
          <ul>
            <li>
              <Typography fontWeight="bold" align='justify' style={{ margin: '2em' }}>
                <b>{"What are the story types and what are the differences?"}</b>
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                <b>{"6-6-6:"}</b>{" Type A (6-6-6) story is composed of exactly three (3) sentences with exactly six (6) words each."}
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                <b>{"66 Words:"}</b>{" Type B (66 Words) story is composed of exactly sixty six (66) words."}
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                <b>{"666 Characters:"}</b>{" Type C (666 Characters) story is composed of exactly six hundred sixty six (666) characters."}
              </Typography>
            </li>
            <li>
              <Typography fontWeight="bold" align='justify' style={{ margin: '2em' }}>
                <b>{"Do I need to provide my contact details and social networking accounts in my submission?"}</b>
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"No. Providing contact details is optional. It is highly recommended to provide these details if you want to "}
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"(i) connect with our readers"}
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"(ii) be properly credited"}
              </Typography>
            </li>
            <li>
              <Typography fontWeight="bold" align='justify' style={{ margin: '2em' }}>
                <b>{"How do I validate my story before submission?"}</b>
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"A word/character counter is provided on the submission page. Our team will also validate your submission before posting your story on the website."}
              </Typography>
            </li>
            <li>
              <Typography fontWeight="bold" align='justify' style={{ margin: '2em' }}>
                <b>{"How do I know if my story has been published on the site?"}</b>
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"We will contact you via email if you provided your email address in your story submission. Otherwise, please monitor the website for updates or new content."}
              </Typography>
            </li>
            <li>
              <Typography fontWeight="bold" align='justify' style={{ margin: '2em' }}>
                <b>{"I think my story was not published. What should I do?"}</b>
              </Typography>
              <Typography align='justify' style={{ marginLeft: '2em', marginRight: '2em'}}>
                {"In case your story couldn't be published for whatever reason, we will contact you via email. If you did not provide any contact details please send an email to "}<b>{"666stories666@gmail.com"}</b>{"."}
              </Typography>
            </li>
          </ul>
        </div>

      </div>
    );
  }
}

export default Faqs;
