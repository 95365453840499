import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Button, Card, Icon, Input, Popup } from 'semantic-ui-react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import '../css/Search.css';
import storiesTypeA from '../../files/stories/json/latest_stories-6-6-6.json';
import storiesTypeB from '../../files/stories/json/latest_stories-66-words.json';
import storiesTypeC from '../../files/stories/json/latest_stories-666-char.json';
import _ from 'lodash';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      randomStory: {},
      msg: "Read this story at https://666stories.com/story/",
      link: ""
    }

    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  componentDidMount() {
    var type = Math.floor(Math.random() * 3);
    var storyIndex = 0;
    var storyType = "a";
    var message;
    var messageLink;
    if(type === 0) {
      storyType = "a";
      storyIndex = Math.floor(Math.random() * storiesTypeA.length);
      message = storiesTypeA[storyIndex].story.substring(0,150) + " | " + this.state.msg + storyType + "/" + storiesTypeA[storyIndex].id.toString() + " | @666_stories";
      messageLink = "https://twitter.com/intent/tweet?text=" + encodeURI(message);
      this.setState({ randomStory: storiesTypeA[storyIndex], link: messageLink });
    } else if (type === 1) {
      storyType = "b";
      storyIndex = Math.floor(Math.random() * storiesTypeB.length);
      message = storiesTypeB[storyIndex].story.substring(0,150) + " | " + this.state.msg + storyType + "/" + storiesTypeB[storyIndex].id.toString() + " | @666_stories";
      messageLink = "https://twitter.com/intent/tweet?text=" + encodeURI(message);
      this.setState({ randomStory: storiesTypeB[storyIndex], link: messageLink });
    } else {
      storyType = "c";
      storyIndex = Math.floor(Math.random() * storiesTypeC.length);
      message = storiesTypeC[storyIndex].story.substring(0,150) + " | " + this.state.msg + storyType + "/" + storiesTypeC[storyIndex].id.toString() + " | @666_stories";
      messageLink = "https://twitter.com/intent/tweet?text=" + encodeURI(message);
      this.setState({ randomStory: storiesTypeC[storyIndex], link: messageLink });
    }
  }

  onSearchTextChange(e, data) {
    this.setState({ searchText: data.value })
  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='searchtitle'>Search</h1>

        <div align="center">
          <Input
              placeholder="Search"
              value={this.state.searchText}
              onChange={this.onSearchTextChange}
          />
        </div>

        <div align="center">
          <Link
            to={{
              pathname: "/search/" + this.state.searchText,
            }}
          >
            <Button
              primary
              style={{ marginTop: '10px', color: "white", background: "#000" }}
            >
              Search
            </Button>
          </Link>
        </div>

        {this.state.randomStory.hasOwnProperty("id") &&
          <div align="center">
            <Typography align='center' style={{ marginTop: '3em' }}>
              {"Random Story:"}
            </Typography>
          </div>
        }

        {this.state.randomStory.hasOwnProperty("id") &&
          <div id="randomstorydiv" align="center">
            <table id="randomstorytable">
              <tbody>
                <tr>
                  <td>
                    <Card>
                      <Card.Content header={this.state.randomStory.title} />
                      <Card.Content description={this.state.randomStory.story} textAlign='center' />
                      <Card.Content extra>
                        <span style={{ float: 'left' }}>
                          <Icon name="user" />
                          <Link
                            to={{
                              pathname: '/viewauthor',
                              state: {
                                author: this.state.randomStory.author,
                                twitter: this.state.randomStory.author_contact_details[0],
                                facebook: this.state.randomStory.author_contact_details[1],
                                instagram: this.state.randomStory.author_contact_details[2],
                                email: this.state.randomStory.author_contact_details[3],
                                website: this.state.randomStory.author_contact_details[4],
                              }
                            }}
                          >
                            {this.state.randomStory.author}
                          </Link>
                        </span>
                        <span style={{ float: 'right' }}>
                          <Popup content="Share" trigger={<a href={this.state.link}> <Icon name="share"/> </a>} />
                        </span>
                      </Card.Content>
                    </Card>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

      </div>
    );
  }
}

export default Search;
