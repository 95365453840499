import React from 'react';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';
import { Card, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import '../css/Stories.css';
import storiesTypeA from '../../files/stories/json/stories-6-6-6.json';
import storiesTypeB from '../../files/stories/json/stories-66-words.json';
import storiesTypeC from '../../files/stories/json/stories-666-char.json';
import _ from 'lodash';
import { isMobileOnly } from 'react-device-detect';

class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [{key: "a", text: "6-6-6", value: "a"}, {key: "b", text: "66 Words", value: "b"}, {key: "c", text: "666 Characters", value: "c"}],
      sorting: [{key: "ta", text: "Title (↑)", value: "ta"}, {key: "td", text: "Title (↓)", value: "td"}, {key: "da", text: "Oldest", value: "da"}, {key: "dd", text: "Latest", value: "dd"}],
      sortingType: "dd",
      type: "a",
      msg: "Read this story at https://666stories.com/story/",
      stories: []
    }

    this.onChangeSelection = this.onChangeSelection.bind(this);
    this.onSortingSelection = this.onSortingSelection.bind(this);
  }

  componentDidMount() {
    var storiesSorted = _.orderBy(storiesTypeA, ['created_at'], ['desc']);
    this.setState({stories: [...storiesSorted]});
  }

  onChangeSelection(e, data) {
    this.setState({ type: data.value });
    var storiesSorted = [];
    if(data.value === "a") {
      storiesSorted = _.orderBy(storiesTypeA, ['created_at'], ['desc']);
    } else if (data.value === "b") {
      storiesSorted = _.orderBy(storiesTypeB, ['created_at'], ['desc']);
    } else {
      storiesSorted = _.orderBy(storiesTypeC, ['created_at'], ['desc']);
    }
    this.setState({stories: [...storiesSorted], sortingType: "dd"});
  }

  onSortingSelection(e, data) {
    this.setState({ sortingType: data.value });
    var storiesSorted = [];
    if(data.value === "ta") {
      storiesSorted = _.orderBy(this.state.stories, ['title'], ['asc']);
    } else if(data.value === "td") {
      storiesSorted = _.orderBy(this.state.stories, ['title'], ['desc']);
    } else if(data.value === "da") {
      storiesSorted = _.orderBy(this.state.stories, ['created_at'], ['asc']);
    } else {
      storiesSorted = _.orderBy(this.state.stories, ['created_at'], ['desc']);
    }
    this.setState({stories: [...storiesSorted]});
  }

  renderStoriesTable() {
    if(this.state.stories.length !== 0) {
      // if story if type b and c -> 1 card per row only
      if(this.state.type === "b" || this.state.type === "c" || isMobileOnly) {
        return this.state.stories.map((item, index) => {
          const { id, title, story, author, author_contact_details } = item;
          var message = story.substring(0,150) + "... | " + this.state.msg + this.state.type + "/" + id.toString() + " | @666_stories";
          var link = "https://twitter.com/intent/tweet?text=" + encodeURI(message);
          return(
            <tr>
              <td>
                <Card>
                  <Card.Content header={title} />
                  <Card.Content description={story} textAlign='center' />
                  <Card.Content extra>
                    <span style={{ float: 'left' }}>
                      <Icon name="user" />
                      <Link
                        to={{
                          pathname: '/viewauthor',
                          state: {
                            author: author,
                            twitter: author_contact_details[0],
                            facebook: author_contact_details[1],
                            instagram: author_contact_details[2],
                            email: author_contact_details[3],
                            website: author_contact_details[4],
                          }
                        }}
                      >
                        {author}
                      </Link>
                    </span>
                    <span style={{ float: 'right' }}>
                      <Popup content="Share" trigger={<a href={link}> <Icon name="share"/> </a>} />
                    </span>
                  </Card.Content>
                </Card>
              </td>
            </tr>
          );
        });
      } else {
        var titles_arr = [];
        var stories_arr = [];
        var authors_arr = [];
        var shareLinks_arr = [];
        var contact_details_arr = [];

        return this.state.stories.map((item, index) => {
          const { id, title, story, author, author_contact_details } = item;
          titles_arr.push(title);
          stories_arr.push(story);
          authors_arr.push(author);
          contact_details_arr.push(author_contact_details);

          var message = story.substring(0,150) + " | " + this.state.msg + this.state.type + "/" + id.toString() + " | @666_stories";
          var link = "https://twitter.com/intent/tweet?text=" + encodeURI(message);
          shareLinks_arr.push(link);

          if(index !== 0 && (index+1) % 3 === 0) {
            var titles_arr_copy = [...titles_arr];
            var stories_arr_copy = [...stories_arr];
            var authors_arr_copy = [...authors_arr];
            var shareLinks_arr_copy = [...shareLinks_arr];
            var contact_details_arr_copy = [...contact_details_arr];
            titles_arr = [];
            stories_arr = [];
            authors_arr = [];
            shareLinks_arr = [];
            contact_details_arr = [];
            return(
              <tr>
                <td>
                  <Card>
                    <Card.Content header={titles_arr_copy[0]} />
                    <Card.Content description={stories_arr_copy[0]} textAlign='center' />
                    <Card.Content extra>
                      <span style={{ float: 'left' }}>
                        <Icon name="user" />
                        <Link
                          to={{
                            pathname: '/viewauthor',
                            state: {
                              author: authors_arr_copy[0],
                              twitter: contact_details_arr_copy[0][0],
                              facebook: contact_details_arr_copy[0][1],
                              instagram: contact_details_arr_copy[0][2],
                              email: contact_details_arr_copy[0][3],
                              website: contact_details_arr_copy[0][4],
                            }
                          }}
                        >
                          {authors_arr_copy[0]}
                        </Link>
                      </span>
                      <span style={{ float: 'right' }}>
                        <Popup content="Share" trigger={<a href={shareLinks_arr_copy[0]}> <Icon name="share"/> </a>} />
                      </span>
                    </Card.Content>
                  </Card>
                </td>
                <td>
                  <Card>
                    <Card.Content header={titles_arr_copy[1]} />
                    <Card.Content description={stories_arr_copy[1]} textAlign='center' />
                    <Card.Content extra>
                      <span style={{ float: 'left' }}>
                        <Icon name="user" />
                        <Link
                          to={{
                            pathname: '/viewauthor',
                            state: {
                              author: authors_arr_copy[1],
                              twitter: contact_details_arr_copy[1][0],
                              facebook: contact_details_arr_copy[1][1],
                              instagram: contact_details_arr_copy[1][2],
                              email: contact_details_arr_copy[1][3],
                              website: contact_details_arr_copy[1][4],
                            }
                          }}
                        >
                          {authors_arr_copy[1]}
                        </Link>
                      </span>
                      <span style={{ float: 'right' }}>
                        <Popup content="Share" trigger={<a href={shareLinks_arr_copy[1]}> <Icon name="share"/> </a>} />
                      </span>
                    </Card.Content>
                  </Card>
                </td>
                <td>
                  <Card>
                    <Card.Content header={titles_arr_copy[2]} />
                    <Card.Content description={stories_arr_copy[2]} textAlign='center' />
                    <Card.Content extra>
                      <span style={{ float: 'left' }}>
                        <Icon name="user" />
                        <Link
                          to={{
                            pathname: '/viewauthor',
                            state: {
                              author: authors_arr_copy[2],
                              twitter: contact_details_arr_copy[2][0],
                              facebook: contact_details_arr_copy[2][1],
                              instagram: contact_details_arr_copy[2][2],
                              email: contact_details_arr_copy[2][3],
                              website: contact_details_arr_copy[2][4],
                            }
                          }}
                        >
                          {authors_arr_copy[2]}
                        </Link>
                      </span>
                      <span style={{ float: 'right' }}>
                        <Popup content="Share" trigger={<a href={shareLinks_arr_copy[2]}> <Icon name="share"/> </a>} />
                      </span>
                    </Card.Content>
                  </Card>
                </td>
              </tr>
            );
          } else {
            if(index === this.state.stories.length - 1) {
              if(titles_arr.length === 1) {
                return(
                  <tr>
                    <td>
                      <Card>
                        <Card.Content header={titles_arr[0]} />
                        <Card.Content description={stories_arr[0]} textAlign='center' />
                        <Card.Content extra>
                          <span style={{ float: 'left' }}>
                            <Icon name="user" />
                            <Link
                              to={{
                                pathname: '/viewauthor',
                                state: {
                                  author: authors_arr[0],
                                  twitter: contact_details_arr[0][0],
                                  facebook: contact_details_arr[0][1],
                                  instagram: contact_details_arr[0][2],
                                  email: contact_details_arr[0][3],
                                  website: contact_details_arr[0][4],
                                }
                              }}
                            >
                              {authors_arr[0]}
                            </Link>
                          </span>
                          <span style={{ float: 'right' }}>
                            <Popup content="Share" trigger={<a href={shareLinks_arr[0]}> <Icon name="share"/> </a>} />
                          </span>
                        </Card.Content>
                      </Card>
                    </td>
                  </tr>
                );
              } else {
                return(
                  <tr>
                    <td>
                      <Card>
                        <Card.Content header={titles_arr[0]} />
                        <Card.Content description={stories_arr[0]} textAlign='center' />
                        <Card.Content extra>
                          <span style={{ float: 'left' }}>
                            <Icon name="user" />
                            <Link
                              to={{
                                pathname: '/viewauthor',
                                state: {
                                  author: authors_arr[0],
                                  twitter: contact_details_arr[0][0],
                                  facebook: contact_details_arr[0][1],
                                  instagram: contact_details_arr[0][2],
                                  email: contact_details_arr[0][3],
                                  website: contact_details_arr[0][4],
                                }
                              }}
                            >
                              {authors_arr[0]}
                            </Link>
                          </span>
                          <span style={{ float: 'right' }}>
                            <Popup content="Share" trigger={<a href={shareLinks_arr[0]}> <Icon name="share"/> </a>} />
                          </span>
                        </Card.Content>
                      </Card>
                    </td>
                    <td>
                      <Card>
                        <Card.Content header={titles_arr[1]} />
                        <Card.Content description={stories_arr[1]} textAlign='center' />
                        <Card.Content extra>
                          <span style={{ float: 'left' }}>
                            <Icon name="user" />
                            <Link
                              to={{
                                pathname: '/viewauthor',
                                state: {
                                  author: authors_arr[1],
                                  twitter: contact_details_arr[1][0],
                                  facebook: contact_details_arr[1][1],
                                  instagram: contact_details_arr[1][2],
                                  email: contact_details_arr[1][3],
                                  website: contact_details_arr[1][4],
                                }
                              }}
                            >
                              {authors_arr[1]}
                            </Link>
                          </span>
                          <span style={{ float: 'right' }}>
                            <Popup content="Share" trigger={<a href={shareLinks_arr[1]}> <Icon name="share"/> </a>} />
                          </span>
                        </Card.Content>
                      </Card>
                    </td>
                  </tr>
                );
              }
            }
          }
        });
      }
    }
  }

  render() {
    return(
      <div>
        <PersistentDrawerLeft />

        <h1 id='storiestitle'>Stories</h1>

        <div id="filter" align="center">
          <table id="storiessortingtable">
              {!isMobileOnly ?
              <tbody>
                <tr>
                  <td>
                    <Dropdown
                      placeholder='6-6-6'
                      selection
                      options={this.state.types}
                      style={{ marginRight: '5px', marginBottom: '10px' }}
                      onChange={this.onChangeSelection}
                      value={this.state.type}
                    />
                    <Dropdown
                      placeholder='Sort by'
                      selection
                      options={this.state.sorting}
                      style={{ marginRight: '5px', marginBottom: '10px' }}
                      onChange={this.onSortingSelection}
                      value={this.state.sortingType}
                    />
                  </td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr>
                  <td>
                    <Dropdown
                      placeholder='6-6-6'
                      selection
                      options={this.state.types}
                      style={{ marginRight: '5px', marginBottom: '10px' }}
                      onChange={this.onChangeSelection}
                      value={this.state.type}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Dropdown
                      placeholder='Sort by'
                      selection
                      options={this.state.sorting}
                      style={{ marginRight: '5px', marginBottom: '10px' }}
                      onChange={this.onSortingSelection}
                      value={this.state.sortingType}
                    />
                  </td>
                </tr>
              </tbody>}
          </table>
        </div>

        <div id="storiestablediv" align="center">
          <table id="storiestable">
            <tbody>
              {this.renderStoriesTable()}
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

export default Stories;
