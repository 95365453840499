import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Latest from './pages/js/Latest';
import About from './pages/js/About';
import Faqs from './pages/js/Faqs';
import Photos from './pages/js/Photos';
import PrivacyPolicy from './pages/js/PrivacyPolicy';
import ReadTypeA from './pages/js/ReadTypeA';
import ReadTypeB from './pages/js/ReadTypeB';
import ReadTypeC from './pages/js/ReadTypeC';
import Search from './pages/js/Search';
import SearchResults from './pages/js/SearchResults';
import Stories from './pages/js/Stories';
import Submit from './pages/js/Submit';
import ViewAuthor from './pages/js/ViewAuthor';

function Home(props) {
  return(
    <Switch>
      <Route exact path='/' component={Latest} />
      <Route exact path='/about' component={About} />
      <Route exact path='/faqs' component={Faqs} />
      <Route exact path='/photos' component={Photos} />
      <Route exact path='/privacypolicy' component={PrivacyPolicy} />
      <Route exact path='/search' component={Search} />
      <Route exact path='/search/:text' component={SearchResults} />
      <Route exact path='/stories' component={Stories} />
      <Route exact path='/story/a/:id' component={ReadTypeA} />
      <Route exact path='/story/b/:id' component={ReadTypeB} />
      <Route exact path='/story/c/:id' component={ReadTypeC} />
      <Route exact path='/submit' component={Submit} />
      <Route exact path='/viewauthor' component={ViewAuthor} />
    </Switch>
  );
}

export default Home;
